import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import { SectionDescription } from "src/ui-treact/components/misc/Typography.js";
import { Link } from "../../utils/general.js";

// const Container = tw.div`flex w-full flex-col items-center bg-gray-100 py-8 px-16 md:px-56`;
const Container = styled.div`
  ${tw`flex flex-col items-center w-full py-40 pb-0 bg-white bg-cover`}
  background-image: url(${props=>props.background_image});
  @media (max-width: 768px) {
    ${tw`py-8`}
  }
`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col flex-wrap items-center w-full mx-auto md:items-stretch md:flex-row md:justify-center`}
`;
const Subheading = tw(SubheadingBase)`mb-4 uppercase tracking-widest font-normal text-primary-500`;
const Heading = tw(SectionHeading)`w-full font-medium`;
const Description = tw(SectionDescription)`w-full text-center text-black`;

const HighlightedText = tw.span`text-primary-500`;

// const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`max-w-md md:w-1/2 lg:w-1/3`}
`;
const Card = styled(Link)`
${tw`flex flex-col items-center h-full px-2 py-8 mx-4 text-center sm:flex-row sm:items-start sm:text-left hover:cursor-pointer hover:no-underline`}
@media (max-width: 768px) {
  ${tw`py-2`}
}
.imageContainer {
  ${tw`flex-shrink-0 p-5 text-center border rounded-full`}
  img {
    ${tw`w-8 h-8 max-w-full`}
  }

}

.textContainer {
  ${tw`mt-4 sm:ml-4 sm:mt-2 `}
}

.title {
  ${tw`mt-4 text-lg font-medium leading-none tracking-wide text-black`}
}

.description { 
  ${tw`mt-1 text-sm font-normal leading-loose text-black text-gray-600 sm:mt-4`}
}
`
// const Card = styled.div`

// `;

const DecoratorBlob = styled.div`
  ${tw`absolute bottom-0 right-0 w-64 transform translate-x-32 translate-y-48 opacity-25 pointer-events-none `}
`;

const PrimaryAction = styled.a`
${tw`px-8 py-3 mx-2 mt-10 text-sm font-bold transition duration-300 rounded shadow sm:text-base sm:mt-16 sm:px-8 sm:py-4 hover:no-underline`}
background-color:${
  props=>props.background_color
};
color:${
  props=>props.text_color
};
`

const Features = ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
       ...finalData
  }  
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */


  return (
    <Container background_image={content.background_image}>
      <ThreeColumnContainer>
        {content.sub_heading && <Subheading>{content.sub_heading}</Subheading>}
        <Heading>{content.heading} <HighlightedText>{content.highlightesText}</HighlightedText></Heading>
        {content.description && <Description>{content.description}</Description>}
        {/* <VerticalSpacer /> */}
        {content.cards ? content.cards.map((card, i) => (
          <Column key={i}>
            <Card to={card.url}>
              <span className="imageContainer">
                <img src={card.img_src} alt="" />                
              </span>
              <span className="textContainer">
                <span className="title">{card.heading || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        )) : null}
      </ThreeColumnContainer>
      { content.button ? <PrimaryAction
        href={content.button?.href}
        background_color={content.background_color}
        text_color={content.button?.text_color}
        >
        {content.button?.text}
      </PrimaryAction> : null }
      
      {/* <DecoratorBlob /> */}
    </Container>
  );
};


export default Features;
